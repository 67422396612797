import http from "../../http-common";

export default {
    state:{
        account     : null,
        customer    : null,
        invoice    : null,
        active      : false,
        confirmed   : false,
        paginated_list_transactions    : null,
        paginated_list_invoices    : null,
    },
    mutations:{

        SET_FC_ACCOUNT:(state,_value)=>{
            state.account = _value;
        },
        SET_FC_INVOICE:(state,_value)=>{
            state.invoice = _value;
        },
        SET_FC_CUSTOMER:(state,_value)=>{
            state.customer = _value;
        },
        SET_FC_ACTIVE:(state,_value)=>{
            state.active = _value;
        },
        SET_FC_CONFIRMED:(state,_value)=>{
            state.confirmed = _value;
        },

        FC_ADD_PAGINATED_LIST_TRANSACTIONS(state, _value)
        {
            if(typeof state.paginated_list_transactions  !== "undefined" && state.paginated_list_transactions  !== null)
            {
                if(typeof _value !== "undefined" && _value !== null && _value.length > 0)
                {
                    for(let i=0;i<_value.length;i++)
                        state.paginated_list_transactions.push(_value[i]);
                }
                else
                {
                    state.paginated_list_transactions  = null;
                }
            }
            else
            {
                state.paginated_list_transactions  = _value;
            }
        },
        FC_SET_PAGINATED_LIST_TRANSACTIONS(state, _value)
        {
            state.paginated_list_transactions = _value;
        },

        FC_ADD_PAGINATED_LIST_INVOICES(state, _value)
        {
            console.log("FC_ADD_PAGINATED_LIST_INVOICES");
            if(typeof state.paginated_list_invoices  !== "undefined" && state.paginated_list_invoices  !== null)
            {
                if(typeof _value !== "undefined" && _value !== null && _value.length > 0)
                {
                    for(let i=0;i<_value.length;i++)
                        state.paginated_list_invoices.push(_value[i]);
                }
                else
                {
                    state.paginated_list_invoices  = null;
                }
            }
            else
            {
                state.paginated_list_invoices  = _value;
            }
        },
        FC_SET_PAGINATED_LIST_INVOICES(state, _value)
        {
            state.paginated_list_invoices = _value;
        },
        FC_RESET_STATE(state,value)
        {
            console.log("FC_RESET_STATE");
            state.account   = null;
            state.customer  = null;
            state.invoice  = null;
            state.active    = false;
            state.confirmed = false;
            state.paginated_list_transactions = null;
            state.paginated_list_invoices    = null;
        }

    },
    actions:{


        SET_FC_INVOICE : ({commit}, _value) =>
        {
            commit("SET_FC_INVOICE", _value);
        },
        FC_ADD_PAGINATED_LIST_TRANSACTIONS : ({commit}, _value) =>
        {
            commit("FC_ADD_PAGINATED_LIST_TRANSACTIONS", _value);
        },
        FC_SET_PAGINATED_LIST_TRANSACTIONS : ({commit}, _value) =>
        {
            commit("FC_SET_PAGINATED_LIST_TRANSACTIONS", _value);
        },
        FC_ADD_PAGINATED_LIST_INVOICES : ({commit}, _value) =>
        {
            commit("FC_ADD_PAGINATED_LIST_INVOICES", _value);
        },
        FC_SET_PAGINATED_LIST_INVOICES : ({commit}, _value) =>
        {
            commit("FC_SET_PAGINATED_LIST_INVOICES", _value);
        },
        FC_GET_CUSTOMER_EXISTS: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/foodcredit/customer/exists',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof _response.data !== "undefined" && _response.data !== null)
                        {
                            if(typeof _response.data.data !== "undefined" && _response.data.data !== null)
                            {
                                console.log("Store: FC_GET_CUSTOMER_EXISTS:"+JSON.stringify(_response.data.data.account));
                                commit('SET_FC_CUSTOMER',_response.data.data.customer);
                                dispatch('SET_CUSTOMER_INFO', _response.data.data.customer);
                                commit('SET_FC_ACCOUNT',_response.data.data.account);
                                commit('SET_FC_ACTIVE',_response.data.data.active);
                                commit('SET_FC_CONFIRMED',_response.data.data.confirmed);
                            }
                            else
                            {
                                commit('SET_MESSAGES', ["An error occurred, please try again later"])
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        FC_GET_CUSTOMER_REPORT: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/foodcredit/reports/customer',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof _response.data !== "undefined" && _response.data !== null)
                        {
                            if(typeof _response.data.data !== "undefined" && _response.data.data !== null)
                            {
                                if(typeof _formData !== "undefined" && _formData !== null && typeof _formData.page !== "undefined" && _formData.page !== null && _formData.page > 1)
                                {
                                    commit("FC_ADD_PAGINATED_LIST_TRANSACTIONS", _response.data.data);
                                }
                                else
                                {
                                    commit("FC_SET_PAGINATED_LIST_TRANSACTIONS", _response.data.data);
                                }
                            }
                            else
                            {
                                commit('SET_MESSAGES', ["An error occurred, please try again later"])
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        FC_GET_CUSTOMER_INVOICES: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/foodcredit/invoices/account',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof _response.data !== "undefined" && _response.data !== null)
                        {
                            if(typeof _response.data.data !== "undefined" && _response.data.data !== null)
                            {
                                if(typeof _formData !== "undefined" && _formData !== null && typeof _formData.page !== "undefined" && _formData.page !== null && _formData.page > 1)
                                {
                                    console.log("FC_GET_CUSTOMER_INVOICES: if ");

                                    commit("FC_ADD_PAGINATED_LIST_INVOICES", _response.data.data);
                                }
                                else
                                {
                                    console.log("FC_GET_CUSTOMER_INVOICES: else : "+_response.data.data);
                                    commit("FC_SET_PAGINATED_LIST_INVOICES", _response.data.data);
                                }
                            }
                            else
                            {
                                commit('SET_MESSAGES', ["An error occurred, please try again later"])
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        FC_GET_CUSTOMER_INVOICE: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/foodcredit/invoices/view',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof _response.data !== "undefined" && _response.data !== null)
                        {
                            if(typeof _response.data.data !== "undefined" && _response.data.data !== null)
                            {

                                console.log("FC_GET_CUSTOMER_INVOICE: "+_response.data.data);
                                commit("SET_FC_INVOICE", _response.data.data);

                            }
                            else
                            {
                                commit('SET_MESSAGES', ["An error occurred, please try again later"])
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        FC_DOWNLOAD_CUSTOMER_INVOICE: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/invoice',
                    method: "GET",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

    }
}